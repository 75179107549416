type Options = {
  callback?(): void;
  offsetOnUp?: number;
};

export function scrollToByHash(hash: string, options: Options = {}): void {
  const {callback, offsetOnUp = 0} = options;
  try {
    const element = hash ? document.querySelector(hash) : undefined;
    if (!element) {
      return undefined;
    }
    const elementTop = element.getBoundingClientRect().top;
    const documentScrollTop = document.documentElement.scrollTop;
    const offset = elementTop < 0 ? offsetOnUp : 0;

    window.scrollTo({
      behavior: 'smooth',
      top: documentScrollTop + elementTop - offset,
    });

    if (callback) {
      callback();
    }
  } catch (e) {
    return undefined;
  }
}
