import cn from 'classnames';
import {Props as VideoProps, YouTubeVideoPlayer} from 'components/Main/YouTubeVideoPlayer';
import {Props as PromoNewsCardProps, PromoNewsCard} from 'components/PromoNewsCard';
import {Props as PromoSocialMediaCardProps, PromoSocialMediaCard} from 'components/PromoSocialMediaCard/index';
import React from 'react';
import styles from './index.module.scss';

export type Card = (
  | (PromoSocialMediaCardProps & {type: 'socialMedia'})
  | (PromoNewsCardProps & {type: 'news'})
  | (VideoProps & {type: 'video'})
) & {position?: {columnEnd?: number; columnStart?: number; rowEnd?: number; rowStart?: number}};

export type Props = {
  cards: Card[];
  title: string;
};

export const News = ({cards, title}: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <div className={styles.content}>
        {cards.map((card, index) => {
          const {position, ...props} = card;
          const {columnStart, columnEnd, rowStart, rowEnd} = position || {};

          switch (props.type) {
            case 'socialMedia':
              return (
                <div
                  className={cn(styles.card, {
                    [styles[`columnStart${columnStart}`]]: columnStart,
                    [styles[`columnEnd${columnEnd}`]]: columnEnd,
                    [styles[`rowStart${rowStart}`]]: rowStart,
                    [styles[`rowEnd${rowEnd}`]]: rowEnd,
                  })}
                  key={index}
                >
                  <PromoSocialMediaCard {...props} />
                </div>
              );
            case 'news':
              return (
                <div
                  className={cn(styles.card, {
                    [styles[`columnStart${columnStart}`]]: columnStart,
                    [styles[`columnEnd${columnEnd}`]]: columnEnd,
                    [styles[`rowStart${rowStart}`]]: rowStart,
                    [styles[`rowEnd${rowEnd}`]]: rowEnd,
                  })}
                  key={index}
                >
                  <PromoNewsCard {...props} />
                </div>
              );
            case 'video':
              return (
                <div
                  className={cn(styles.video, {
                    [styles[`columnStart${columnStart}`]]: columnStart,
                    [styles[`columnEnd${columnEnd}`]]: columnEnd,
                    [styles[`rowStart${rowStart}`]]: rowStart,
                    [styles[`rowEnd${rowEnd}`]]: rowEnd,
                  })}
                  key={index}
                >
                  <YouTubeVideoPlayer {...props} startImmediately />
                </div>
              );
          }
        })}
      </div>
    </div>
  );
};
