import {Footer as FooterBase, FooterTestId as FooterBaseTestId} from 'components/Footer';
import {Reducer} from 'components/Layout/Reducer';
import {SectionSeo, DEFAULT_RU_TAGS} from 'components/Main/SectionSeo';
import {useLanguage} from 'lib/language/hooks';
import {Language} from 'lib/language/types';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

export type FooterTestId = FooterBaseTestId;

type Props = TestIdProp<FooterTestId> & {
  isAnalyticsPage?: boolean;
  onConnect?(sectionId: string): void;
};

export function Footer({testId, isAnalyticsPage, onConnect}: Props): React.ReactElement {
  const language = useLanguage();

  return (
    <div className={styles.container}>
      {language === Language.RU && <SectionSeo tags={DEFAULT_RU_TAGS} />}
      <Reducer>
        <FooterBase isAnalyticsPage={isAnalyticsPage} onOpenConsultationDialog={onConnect} testId={testId} />
      </Reducer>
    </div>
  );
}
