import {Link as BaseLink, LinkProps, LinkTestId} from 'components/Link';
import {ElementPositionOnPage} from 'events/types/ElementPositionOnPage';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';

export type ExternalLinkTestId = LinkTestId;

type Props = LinkProps &
  TestIdProp<ExternalLinkTestId> & {
    position?: ElementPositionOnPage;
  };

export function ExternalLink({onClick, href, position = 'body', ...rest}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(event);
      }

      analytics.sendEvent({
        payload: {
          link: event.currentTarget.href,
          pageUrl: window.location.href,
          position,
          source: appEnv.currentPageName,
        },
        type: 'externalLinkClick',
      });
    },
    [onClick, position, appEnv, analytics],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BaseLink {...rest} href={href} onClick={handleClick} />
  );
}
