/**
 * Use buttons from uikit
 * TODO: PRO-3566
 */
import cnb from 'classnames/bind';
import {Link, LinkProps} from 'components/Link';
import {TestIdProp} from 'lib/testing/types';
import React, {forwardRef} from 'react';
import styles from './index.module.scss';

export type ButtonTestId = unknown;

type ButtonSize = 'xl' | 'l' | 'm' | 'sm';

type ButtonColor = 'white' | 'black' | 'red' | 'gray' | 'transparent';

type ButtonBorder = 'black' | 'white' | 'none';

type ButtonContentProps = {
  border?: ButtonBorder;
  children: React.ReactNode;
  color?: ButtonColor;
  iconLeft?: React.ReactNode;
  rounded?: boolean;
  size?: ButtonSize;
};

type ButtonProps = TestIdProp<ButtonTestId> &
  Pick<JSX.IntrinsicElements['button'], 'disabled' | 'tabIndex' | 'onClick'> &
  ButtonContentProps;

type ButtonLinkProps = Omit<LinkProps, 'className' | 'ref'> & ButtonContentProps;

const cn = cnb.bind(styles);

function ButtonContent({
  iconLeft,
  children,
  size = 'l',
  color = 'black',
  border = 'none',
  rounded = false,
}: ButtonContentProps) {
  const withIcon = Boolean(iconLeft);

  return (
    <div
      className={cn(
        styles.content,
        `size-${size}`,
        `color-${color}`,
        `border-${border}`,
        rounded && styles.rounded,
        withIcon && styles.withIcon,
      )}
    >
      {iconLeft && <div className={styles.icon}>{iconLeft}</div>}
      {children}
    </div>
  );
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {children, size, iconLeft, color = 'black', rounded = false, border, onClick, tabIndex, disabled, testId},
    ref,
  ): React.ReactElement => {
    return (
      <button
        className={styles.button}
        data-test-id={testId}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        tabIndex={tabIndex}
        type='button'
      >
        <ButtonContent border={border} color={color} iconLeft={iconLeft} rounded={rounded} size={size}>
          {children}
        </ButtonContent>
      </button>
    );
  },
);

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({children, size, iconLeft, border, color = 'black', rounded = false, ...restProps}, ref): React.ReactElement => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Link {...restProps} className={styles.link} ref={ref}>
        <ButtonContent border={border} color={color} iconLeft={iconLeft} rounded={rounded} size={size}>
          {children}
        </ButtonContent>
      </Link>
    );
  },
);
