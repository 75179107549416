import {Image} from 'components/Image';
import {Link, LinkTarget} from 'components/Link';
import ArrowIcon from 'icons/jp-arrow-up-right.svg';
import {useAnalytics} from 'lib/analytics';
import React, {useCallback} from 'react';
import styles from './index.module.scss';

export type Props = {
  description?: string;
  illustration: string;
  link: string;
  target?: LinkTarget;
  title: string;
};

export const PromoNewsCard = ({title, link, description, target, illustration}: Props): React.ReactElement => {
  const analytics = useAnalytics();
  const handleLinkClick = useCallback(() => {
    link &&
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          source: 'socialMedia',
          targetUrl: link,
        },
        type: 'navigationClick',
      });
  }, [analytics.sendEvent, link]);

  return (
    <Link className={styles.card} href={link} onClick={handleLinkClick} target={target}>
      <div className={styles.imageWrapper}>
        <Image className={styles.image} src={illustration} />
        <div className={styles.arrow}>
          <ArrowIcon />
        </div>
      </div>
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </Link>
  );
};
