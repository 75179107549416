import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Review, Data as ReviewData} from '../Review';
import {ReviewTab} from '../ReviewTab';
import styles from './index.module.scss';

export type Tab = {
  id: string;
  label: ExtMessageDescriptor;
  review: ReviewData;
};

type Props = {
  activeTabId: string;
  onChange(tabId: string): void;
  tabs: Tab[];
};

export function ReviewTabs({tabs, activeTabId, onChange}: Props): React.ReactElement {
  const intl = useIntl();
  const activeTab = useMemo(() => tabs.find((tabItem) => tabItem.id === activeTabId), [tabs, activeTabId]);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div className={styles.inner}>
          {tabs.map((tabItem) => (
            <div className={styles.tabWrap} key={tabItem.id}>
              <ReviewTab active={tabItem.id === activeTabId} onClick={onChange} tabId={tabItem.id}>
                {buildMessage(intl, tabItem.label)}
              </ReviewTab>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.content}>{activeTab && <Review review={activeTab.review} />}</div>
    </div>
  );
}
