import {Reducer} from 'components/Layout/Reducer';
import {Section, SectionColor} from 'components/Main/Section';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback, useRef} from 'react';
import {useIntl} from 'react-intl';
import {CardMedia} from './CardMedia';
import styles from './index.module.scss';
import {SliderControls} from './SliderControls';
import {SectionMediaDataItem} from './types';

export type SectionMediaTestId = unknown;

type Props = TestIdProp<SectionMediaTestId> & {
  color?: SectionColor;
  data: SectionMediaDataItem[];
  title: ExtMessageDescriptor;
};

export function SectionMedia({color, data, testId, title}: Props): React.ReactElement {
  const intl = useIntl();
  const sliderRef = useRef<HTMLDivElement>(null);
  const slidesRef = useRef<HTMLDivElement>(null);

  const handleLeft = useCallback(() => {
    if (sliderRef.current && slidesRef.current) {
      const {children} = slidesRef.current;
      const currentScrollLeft = Math.floor(sliderRef.current.scrollLeft);

      for (let prevOffset = 0, i = 0; i < children.length; i++) {
        const slideElement = children[i] as HTMLElement;
        const slideOffsetLeft = slideElement.offsetLeft;

        if (slideOffsetLeft >= currentScrollLeft) {
          sliderRef.current.scrollTo({
            behavior: 'smooth',
            left: prevOffset,
            top: 0,
          });

          break;
        }

        prevOffset = slideOffsetLeft;
      }
    }
  }, []);

  const handleRight = useCallback(() => {
    if (sliderRef.current && slidesRef.current) {
      const {children} = slidesRef.current;
      const currentScrollLeft = Math.ceil(sliderRef.current.scrollLeft);

      for (let i = 0; i < children.length; i++) {
        const slideElement = children[i] as HTMLElement;
        const slideOffsetLeft = slideElement.offsetLeft;

        if (slideOffsetLeft > currentScrollLeft) {
          sliderRef.current.scrollTo({
            behavior: 'smooth',
            left: slideOffsetLeft,
            top: 0,
          });

          break;
        }
      }
    }
  }, []);

  return (
    <Section color={color} testId={testId} title={buildMessage(intl, title)} wideContent>
      <div className={styles.cards}>
        <div className={styles.slider} ref={sliderRef}>
          <div className={styles.slides} ref={slidesRef}>
            {data.map((item) => (
              <div className={styles.slide} key={item.linkHref}>
                <CardMedia
                  key={item.linkHref}
                  linkHref={item.linkHref}
                  linkText={item.linkText}
                  logoAlt={item.logoAlt}
                  logoSrc={item.logoSrc}
                >
                  {item.content}
                </CardMedia>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Reducer>
        <div className={styles.controls}>
          <SliderControls onLeft={handleLeft} onRight={handleRight} />
        </div>
      </Reducer>
    </Section>
  );
}
