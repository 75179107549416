import cn from 'classnames';
import React from 'react';
import styles from './index.module.scss';

export type Props = {
  actions?: React.ReactElement | React.ReactElement[];
  color?: 'gray' | 'black';
  icon: React.ReactElement;
  title: string;
};

export const PromoSocialMediaCard = ({actions, title, icon, color = 'black'}: Props): React.ReactElement => {
  return (
    <div className={cn(styles.card, styles[color])}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        <div className={styles.actions}>{actions}</div>
      </div>
    </div>
  );
};
