import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {ExternalLink} from 'components/Main/ExternalLink';
import {Typography} from 'components/Main/Typography';
import {ImageBundle} from 'lib/image/types';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export type Data = {
  imageAlt?: string;
  imageSrc?: ImageBundle | string;
  info?: ExtMessageDescriptor;
  linkHref?: string;
  linkText?: ExtMessageDescriptor;
  messages: ExtMessageDescriptor[];
  name: ExtMessageDescriptor;
};

export type Props = {
  review: Data;
};

export function Review({review}: Props): React.ReactElement {
  const intl = useIntl();
  const {imageAlt, imageSrc, name, messages, linkText, linkHref} = review;

  return (
    <Card border='black' color='gray' padding={['l', 'xl']}>
      <div className={styles.container}>
        {imageSrc && (
          <div className={styles.figure}>
            <Image alt={imageAlt} className={styles.image} loading='lazy' sizes={180} src={imageSrc} />
          </div>
        )}
        <div className={styles.content}>
          <Typography kind='promo' size='m' variant='subtitle'>
            {buildMessage(intl, name)}
          </Typography>
          <div className={styles.messages}>
            {messages.map((message, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Typography key={index} kind='promo' size='l' variant='text'>
                {buildMessage(intl, message)}
              </Typography>
            ))}
          </div>
          {!!linkHref && (
            <ExternalLink className={styles.link} href={linkHref} target='_blank'>
              <Typography alternative color='red' component='span' kind='promo' size='m' variant='subtitle'>
                →
              </Typography>
              <Typography kind='promo' size='l' variant='text'>
                <span className={styles.text}>{buildMessage(intl, linkText)}</span>
              </Typography>
            </ExternalLink>
          )}
        </div>
      </div>
    </Card>
  );
}
