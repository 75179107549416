import {useCallbackWithoutArgs} from 'lib/hooks';
import React from 'react';
import ArrowLeftIcon from './arrow-left.svg';
import ArrowRightIcon from './arrow-right.svg';
import styles from './index.module.scss';

type Props = {
  onLeft(): void;
  onRight(): void;
};

export function SliderControls({onLeft, onRight}: Props): React.ReactElement {
  const handleLeft = useCallbackWithoutArgs(onLeft);
  const handleRight = useCallbackWithoutArgs(onRight);

  return (
    <div className={styles.container}>
      <button className={styles.control} onClick={handleLeft} type='button'>
        <ArrowLeftIcon />
      </button>
      <button className={styles.control} onClick={handleRight} type='button'>
        <ArrowRightIcon />
      </button>
    </div>
  );
}
