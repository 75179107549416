import cnb from 'classnames/bind';
import React from 'react';
import styles from './index.module.scss';

type Kind = 'promo' | 'main';
type Size = 's' | 'm' | 'l' | 'xl';
type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle' | 'text' | 'accent' | 'line';
type Color = 'white' | 'black' | 'red';

type Props = {
  alternative?: boolean;
  children: React.ReactNode;
  color?: Color;
  component?: React.ElementType;
  kind: Kind;
  size?: Size;
  variant: Variant;
};

const cn = cnb.bind(styles);
const componentByVariant: Record<Variant, React.ElementType> = {
  accent: 'span',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  line: 'span',
  subtitle: 'span',
  text: 'span',
};

export function Typography({
  kind,
  size = 'm',
  color,
  variant,
  children,
  component,
  alternative,
}: Props): React.ReactElement {
  const Tag = component || componentByVariant[variant];
  const className = cn(kind, variant, styles.root, size && `size-${size}`, color && `color-${color}`, {
    [styles.alternative]: alternative,
  });

  return <Tag className={className}>{children}</Tag>;
}
