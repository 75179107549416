import cnb from 'classnames/bind';
import {isString} from 'lib/guards';
import React from 'react';
import styles from './index.module.scss';

export type Size = 'm' | 'sm';
export type Rounded = 'l' | 'm';
export type Overflow = 'scroll' | 'visible' | 'hidden';
export type Color = 'accent' | 'black' | 'black-deep' | 'white' | 'gray' | 'box';
export type Border = 'black' | 'white' | 'red' | 'gray' | 'none';
export type PaddingSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'none';
export type CardPadding = PaddingSize | [PaddingSize?, PaddingSize?];
export type Props = {
  border?: Border;
  children: React.ReactNode;
  color: Color;
  gridArea?: string;
  onClick?(): void;
  overflow?: Overflow;
  /**
   * @example
   * ['m', 'xl'], where:
   * xs = 24px
   * s = 32px
   * m = 40px
   * l = 60px
   * xl = 80px
   */
  padding?: PaddingSize | [PaddingSize?, PaddingSize?];
  rounded?: Rounded;
};

const DEFAULT_PADDING: [PaddingSize?, PaddingSize?] = [];
const cn = cnb.bind(styles);

export function Card({
  onClick,
  color,
  border = 'none',
  rounded = 'l',
  children,
  gridArea,
  padding = DEFAULT_PADDING,
  overflow = 'visible',
}: Props): React.ReactElement {
  const [verticalPaddingSize = 'none', horizontalPaddingSize = 'none'] = isString(padding)
    ? [padding, padding]
    : padding;

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      className={cn(
        styles.container,
        `color-${color}`,
        `border-${border}`,
        `rounded-${rounded}`,
        `overflow-${overflow}`,
        `vertical-padding-${verticalPaddingSize}`,
        `horizontal-padding-${horizontalPaddingSize}`,
      )}
      onClick={onClick}
      style={{gridArea}}
      tabIndex={-1}
    >
      {children}
    </div>
  );
}
