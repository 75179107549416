import cn from 'classnames';
import {useElementPreview} from 'lib/hooks';
import React, {useCallback, useRef, useState} from 'react';
import {DotsLoading} from 'uikit/DotsLoading';
import styles from './index.module.scss';

export type Props = {
  className?: string;
  src: string;
  startImmediately?: boolean;
};

export function YouTubeVideoPlayer({src, className, startImmediately = false}: Props): React.ReactElement {
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [mediaSrc, setMediaSrc] = useState('');

  const handleLoad = useCallback(() => setLoading(false), []);
  const handlePreview = useCallback(() => {
    setMediaSrc(src);
  }, [src]);

  useElementPreview(iframeRef, handlePreview, {rootMargin: '200px'});

  return (
    <div className={cn(className, styles.container)}>
      {loading && (
        <div className={styles.loader}>
          <DotsLoading />
        </div>
      )}
      <iframe
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        className={styles.iframe}
        frameBorder='0'
        loading='lazy'
        onLoad={handleLoad}
        ref={iframeRef}
        src={startImmediately ? src : mediaSrc}
        title='YouTube video player'
      />
    </div>
  );
}
