import cn from 'classnames';
import {Card as CardBase, Props as CardBaseProps, Size} from 'components/Main/Card';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export type Align = 'left' | 'center' | 'right';
export type Props = Omit<CardBaseProps, 'children'> & {
  align?: Align;
  media?: React.ReactNode;
  size?: Size;
  subtitle?: ExtMessageDescriptor;
  title?: ExtMessageDescriptor;
};

export function CardInfo({
  color,
  border,
  align = 'center',
  media,
  title,
  subtitle,
  gridArea,
  padding = ['s', 'm'],
  size = 'm',
}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <CardBase border={border} color={color} gridArea={gridArea} padding={padding}>
      <div className={cn(styles.content, styles[align], styles[size])}>
        {media}
        <div className={styles.header}>
          {title && <div className={styles.title}>{buildMessage(intl, title)}</div>}
          {subtitle && <div className={styles.subtitle}>{buildMessage(intl, subtitle)}</div>}
        </div>
      </div>
    </CardBase>
  );
}
