import classNames from 'classnames';
import {FADE_IN_ROOT_MARGIN, FADE_IN_THRESHOLD} from 'components/Main/lib';
import {useElementPreview} from 'lib/hooks/useElementPreview';
import React, {useRef, useCallback, useState} from 'react';
import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
  gridArea?: string;
  rootMargin?: string;
  threshold?: number;
};

export function FadeIn({
  children,
  gridArea,
  threshold = FADE_IN_THRESHOLD,
  rootMargin = FADE_IN_ROOT_MARGIN,
}: Props): React.ReactElement {
  const [className, setClassName] = useState<string | undefined>(undefined);
  const targetRef = useRef<HTMLDivElement>(null);

  const handlePreview = useCallback(() => {
    setClassName(styles.animation);
  }, []);

  useElementPreview(targetRef, handlePreview, {rootMargin, threshold});

  return (
    <div className={classNames(styles.container, className)} ref={targetRef} style={{gridArea}}>
      {children}
    </div>
  );
}
