import cn from 'classnames';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

export type Props = TestIdProp<unknown> & {
  blockA: React.ReactNode;
  blockB: React.ReactNode;
  blockC: React.ReactNode;
  gridArea?: string;
};

export function TripleLayout({blockA, blockB, blockC, gridArea, testId}: Props): React.ReactElement {
  return (
    <div className={styles.container} data-test-id={testId} style={{gridArea}}>
      <div className={cn(styles.item, styles.a)}>{blockA}</div>
      <div className={cn(styles.item, styles.b)}>{blockB}</div>
      <div className={cn(styles.item, styles.c)}>{blockC}</div>
    </div>
  );
}
