import cn from 'classnames';
import React from 'react';
import styles from './index.module.scss';

export type BadgeAlign = 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom';

type Props = {
  align: BadgeAlign;
  children: React.ReactNode;
};

export const Badge = ({children, align}: Props): React.ReactElement => (
  <div className={styles.badge}>
    <div className={cn(styles.inner, styles[align])}>{children}</div>
  </div>
);
