import {Image} from 'components/Image';
import {ExternalLink, ExternalLinkTestId} from 'components/Main/ExternalLink';
import {Typography} from 'components/Main/Typography';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.module.scss';

const messages = defineMessages({
  defaultLinkMessage: {
    defaultMessage: 'Link',
    description: 'Main page. Section "Media". Default message for link',
  },
});

export type MediaItemTestId = {
  link: ExternalLinkTestId;
};

export type Props = TestIdProp<MediaItemTestId> & {
  children: React.ReactNode;
  linkHref: string;
  linkText?: React.ReactNode;
  logoAlt?: string;
  logoSrc: string;
};

export function CardMedia({testId, logoSrc, logoAlt, linkHref, linkText, children}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.media} data-test-id={testId}>
      <div className={styles.imageWrap}>
        <Image alt={logoAlt} className={styles.image} loading='lazy' src={logoSrc} />
      </div>
      <div className={styles.text}>
        <Typography kind='promo' size='l' variant='text'>
          {children}
        </Typography>
      </div>
      {linkHref && (
        <ExternalLink className={styles.link} href={linkHref} target='_blank' testId={testId?.link}>
          <Typography alternative color='red' component='span' kind='promo' size='m' variant='subtitle'>
            →
          </Typography>
          <Typography kind='promo' size='l' variant='text'>
            <span className={styles.text}>{linkText ?? intl.formatMessage(messages.defaultLinkMessage)}</span>
          </Typography>
        </ExternalLink>
      )}
    </div>
  );
}
