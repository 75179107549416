import {NavigationId} from 'components/Main/lib/types';
import {Section, SectionColor} from 'components/Main/Section';
import {TestIdProp} from 'lib/testing/types';
import React, {useState} from 'react';
import {ReviewTabs} from './ReviewTabs';
import {SectionReviewsDataItem} from './types';

export type SectionReviewsTestId = unknown;

type Props = TestIdProp<SectionReviewsTestId> & {
  color?: SectionColor;
  data: SectionReviewsDataItem[];
  title: React.ReactNode;
};

export function SectionReviews({color, data, testId, title}: Props): React.ReactElement {
  const [activeTabId, setActiveTabId] = useState(() => data[0].id);

  return (
    <Section color={color} id={NavigationId.REVIEWS} testId={testId} title={title}>
      <ReviewTabs activeTabId={activeTabId} onChange={setActiveTabId} tabs={data} />
    </Section>
  );
}
