import throttle from 'lodash/throttle';
import {useEffect, useState} from 'react';

const SCROLL_THROTTLE_TIMEOUT = 200;
const SCROLL_THRESHOLD_VALUE = 10;

export function useDividerNeeded() {
  const [withDivider, setWithDivider] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      setWithDivider(window.scrollY > SCROLL_THRESHOLD_VALUE);
    }, SCROLL_THROTTLE_TIMEOUT);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return withDivider;
}
