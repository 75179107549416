import cn from 'classnames';
import {Button} from 'components/Main/Button';
import React, {useCallback, useRef} from 'react';
import ArrowIcon from './arrow.svg';
import styles from './index.module.scss';

type Props = {
  active?: boolean;
  children: React.ReactNode;
  onClick(tabId: string): void;
  tabId: string;
};

export function ReviewTab({tabId, onClick, children, active = false}: Props): React.ReactElement {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick(tabId);
      event.currentTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    },
    [tabId, onClick],
  );

  return (
    <Button
      border='black'
      color={active ? 'black' : 'gray'}
      iconLeft={<ArrowIcon className={cn(styles.icon, active && styles.active)} />}
      onClick={handleClick}
      ref={buttonRef}
      rounded
    >
      {children}
    </Button>
  );
}
