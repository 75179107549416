import {Image} from 'components/Image';
import {Card as CardBase, Props as CardBaseProps} from 'components/Main/Card';
import {Typography} from 'components/Main/Typography';
import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export type Align = 'left' | 'center' | 'right';
export type Props = Omit<CardBaseProps, 'children' | 'paddnig'> & {
  illustration: string;
  title?: ExtMessageDescriptor;
};

export function CardCompact({color, border, title, gridArea, illustration, padding = 's'}: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <CardBase border={border} color={color} gridArea={gridArea} padding={padding}>
      <div className={styles.content}>
        <Image className={styles.image} loading='lazy' src={illustration} />
        <Typography kind='promo' size='l' variant='accent'>
          {buildMessage(intl, title)}
        </Typography>
      </div>
    </CardBase>
  );
}
