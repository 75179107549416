import cn from 'classnames';
import {Badge, BadgeAlign} from 'components/Badge';
import {Image} from 'components/Image';
import {Card as CardBase, Props as CardBaseProps} from 'components/Main/Card';
import React from 'react';
import styles from './index.module.scss';

type IllustrationAlign = 'top' | 'bottom';

export type Props = Omit<CardBaseProps, 'children'> & {
  badge?: React.ReactNode;
  badgeAlign?: BadgeAlign;
  illustration: string;
  illustrationAlign?: IllustrationAlign;
  illustrationPassiveSize?: boolean;
};

export function CardMedia({
  badgeAlign = 'right-top',
  badge,
  color,
  border,
  gridArea,
  illustrationAlign,
  illustration,
  illustrationPassiveSize = false,
}: Props): React.ReactElement {
  return (
    <CardBase border={border} color={color} gridArea={gridArea} overflow='hidden'>
      {badge && <Badge align={badgeAlign}>{badge}</Badge>}
      {illustrationPassiveSize ? (
        <div className={styles.passiveImage} style={{backgroundImage: `url(${illustration})`}} />
      ) : (
        <Image
          className={cn(styles.image, illustrationAlign && styles[illustrationAlign])}
          loading='lazy'
          src={illustration}
        />
      )}
    </CardBase>
  );
}
