import {DataState} from '@joomcode/deprecated-utils/dataState';
import cn from 'classnames';
import {ErrorMessage} from 'components/ErrorMessage';
import {InputField} from 'components/FinalForm/InputField';
import {Image} from 'components/Image';
import {Card} from 'components/Main/Card';
import {createForm} from 'final-form';
import {validateEmail} from 'lib/finalForm/validators';
import React, {useEffect, useMemo} from 'react';
import {Form} from 'react-final-form';
import {defineMessages, useIntl} from 'react-intl';
import {Button} from 'uikit/Button';
import {SubscribeFormData, SubscribeHandler} from '../../types';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Subscribe',
    description: '[button] Self service main page. Card subscribe: action button',
  },
  placeholder: {
    defaultMessage: 'Email',
    description: 'Self service main page. Card subscribe: field placeholder',
  },
  subtitle: {
    defaultMessage: 'Receive our news and exclusive content',
    description: 'Self service main page. Card subscribe: subtitle',
  },
  title: {
    defaultMessage: 'Sign up for our newsletter',
    description: 'Self service main page. Card subscribe: title',
  },
});

export type Props = {
  color?: 'accent' | 'black';
  onSubscribe: SubscribeHandler;
  state: DataState;
  subtitle?: string;
  title?: string;
  withIllustration?: boolean;
};

export function CardSubscribe({
  state,
  onSubscribe,
  title,
  subtitle,
  withIllustration = true,
  color = 'black',
}: Props): React.ReactElement {
  const intl = useIntl();
  const form = useMemo(() => {
    return createForm({
      onSubmit: onSubscribe,
    });
  }, [onSubscribe]);

  const titleMessage = title ? title : intl.formatMessage(messages.title);
  const subtitleMessage = subtitle ? subtitle : intl.formatMessage(messages.title);

  const loaded = state === DataState.LOADED;
  const failed = state === DataState.FAILED;
  const loading = state === DataState.LOADING;

  useEffect(() => {
    if (loaded) {
      form.restart();
    }
  }, [loaded]);

  const newType = color === 'accent';

  return (
    <Card color={color} padding={!withIllustration ? undefined : ['s', 'm']} rounded='m'>
      <div className={cn(styles.container, {[styles.withoutIllustration]: !withIllustration, [styles.white]: newType})}>
        {withIllustration && (
          <Image className={styles.image} loading='lazy' src='/illustrations/mainPtBr/subscribe.png' />
        )}
        <div className={cn(styles.body, {[styles.center]: !withIllustration})}>
          <div className={styles.header}>
            <div className={styles.title}>{titleMessage}</div>
            <div className={styles.subtitle}>{subtitleMessage}</div>
          </div>
          <Form<SubscribeFormData> form={form} onSubmit={onSubscribe}>
            {(props) => (
              <form className={cn(styles.form, {[styles.white]: newType})} noValidate onSubmit={props.handleSubmit}>
                <InputField
                  dark={!newType}
                  name='email'
                  placeholder={intl.formatMessage(messages.placeholder)}
                  required
                  size='large'
                  type='email'
                  validate={validateEmail}
                />

                <Button color={newType ? 'black' : 'accent'} loading={loading} size='large' type='submit'>
                  {intl.formatMessage(messages.button)}
                </Button>
                {failed && <ErrorMessage className={styles.error} />}
              </form>
            )}
          </Form>
        </div>
      </div>
    </Card>
  );
}
