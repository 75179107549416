import cn from 'classnames';
import {Reducer} from 'components/Layout/Reducer';
import {FadeIn} from 'components/Main/FadeIn';
import {Typography} from 'components/Main/Typography';
import {useAnalytics} from 'lib/analytics';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import styles from './index.module.scss';

export type SectionColor = 'white' | 'black' | 'gray' | 'bronze';
type Props = TestIdProp<unknown> & {
  children: React.ReactNode;
  color?: SectionColor;
  id?: string;
  title?: React.ReactNode;
  titleCenteringOnMobile?: boolean;
  titleMessage?: React.ReactNode;
  wideContent?: boolean;
};

export function Section({
  titleCenteringOnMobile = false,
  testId,
  children,
  color = 'white',
  id,
  title,
  titleMessage,
  wideContent,
}: Props): React.ReactElement {
  const analytics = useAnalytics();
  const handleSectionClick = useCallback(() => {
    if (id) {
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          section: id,
        },
        type: 'mainSectionClick',
      });
    }
  }, [id]);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <div className={cn(styles.container, styles[color])} data-test-id={testId} id={id} onClick={handleSectionClick}>
      <FadeIn>
        <Reducer>
          {!!title && (
            <div className={cn(styles.titleWrap, titleCenteringOnMobile && styles.centering)}>
              <Typography kind='promo' variant='h2'>
                {title}
              </Typography>
              {!!titleMessage && (
                <Typography alternative kind='promo' size='xl' variant='subtitle'>
                  {titleMessage}
                </Typography>
              )}
            </div>
          )}
        </Reducer>
        {wideContent ? <div>{children}</div> : <Reducer>{children}</Reducer>}
      </FadeIn>
    </div>
  );
}
